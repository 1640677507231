/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import * as singleSpa from 'single-spa';
import {
  Box,
  Typography,
  // eslint-disable-next-line import/no-unresolved
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AuthContext,
  FdTextField,
  FdButton,
  FdCard,
  FdAlert,
  // eslint-disable-next-line import/no-unresolved
} from '@fifthdomain/fe-shared';
import { chain, split } from 'lodash';
import useStyles from './styles';
import {
  getAssessmentIdFromSharedUrl,
  getOrgIdFromSharedUrl,
  getSharedLinkEvent,
  getAffiliatedParams,
} from '../../shared/utils/urlUtils';

const Login = () => {
  const classes = useStyles();
  const {
    handleSignIn,
    error: errorMessage,
    setError,
    loading,
    user,
  } = useContext(AuthContext);

  // doing this because URLSearchParams removes special characters
  const searchEmail = chain(window.location.search)
    .replace('?', '')
    .split('&')
    .map((x) => split(x, '='))
    .value();

  const emailArr = searchEmail?.map((item) =>
    item?.indexOf('email') > -1 ? item[1] : emailArr,
  );

  const emailDefault = emailArr[0]?.toLowerCase().replace(/%20/g, '+');

  const initialValues = {
    email: emailDefault || '',
    password: '',
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email'),
    password: Yup.string().required('Please enter your password'),
  });

  const { control, reset, getValues, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const onSubmit = async () => {
    const { email, password } = getValues();
    await handleSignIn(email.toLowerCase(), password);
    reset();
  };

  const isDev =
    document.location.host === 'localhost:5000' ||
    document.location.host === 'competitions.local:5000';

  if (!isDev) {
    if (user) {
      // segment track event
      /* eslint-disable no-undef */
      analytics.identify(user.username);
    }
  }
  // this pattern will occur when a competition link is shared
  const sharedLinkEvent = getSharedLinkEvent(); // competition or assessment
  const affiliatedParams = getAffiliatedParams();

  useEffect(() => {
    setError('');
  }, [setError]);

  return (
    <Box width="500px" height="428px">
      <FdCard heading="Log In" elevation={24}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Box my={3}>
              {errorMessage && (
                <FdAlert
                  id="failed-user-login"
                  variant="error"
                  message="Incorrect email address or password"
                />
              )}
            </Box>
            <Controller
              control={control}
              name="email"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="signin-email"
                    label="Email Address"
                    required
                    fullWidth
                    error={error}
                    helperText={error ? error.message : null}
                    inputRef={ref}
                    {...rest}
                  />
                </Box>
              )}
            />
            <Controller
              control={control}
              name="password"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="signin-password"
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    error={error}
                    helperText={error ? error.message : null}
                    inputRef={ref}
                    {...rest}
                  />
                </Box>
              )}
            />
            <Box pt={2}>
              <Box className={classes.link}>
                <Link
                  to={
                    sharedLinkEvent
                      ? `/a/forgot-password/e/${sharedLinkEvent}/${getAssessmentIdFromSharedUrl(
                          sharedLinkEvent,
                        )}/org/${getOrgIdFromSharedUrl()}`
                      : affiliatedParams?.isAffiliated
                      ? `/a/forgot-password?email=${affiliatedParams?.email?.toLowerCase()}&isAffiliated=${
                          affiliatedParams?.isAffiliated
                        }&orgId=${affiliatedParams?.orgId}`
                      : '/a/forgot-password'
                  }
                  id="forgot-password-link"
                  className={classes.link}
                >
                  <Typography variant="body2">Forgot password?</Typography>
                </Link>
              </Box>
              <Box display="flex" justifyContent="flex-end" mt={3}>
                {sharedLinkEvent && (
                  <Box mr={1}>
                    <FdButton
                      id="register-button"
                      name="register-button"
                      variant="tertiary"
                      size="large"
                      onClick={() => {
                        const assessmentId =
                          getAssessmentIdFromSharedUrl(sharedLinkEvent);
                        const orgId = getOrgIdFromSharedUrl();
                        singleSpa.navigateToUrl(
                          `/a/register/e/${sharedLinkEvent}/${assessmentId}/org/${orgId}`,
                        );
                      }}
                    >
                      Register Instead
                    </FdButton>
                  </Box>
                )}
                <FdButton
                  id="signin-button"
                  name="signin-button"
                  color="primary"
                  type="submit"
                  value="Submit"
                  size="large"
                >
                  {loading ? 'Loading...' : 'Login'}
                </FdButton>
              </Box>
            </Box>
          </Box>
        </form>
      </FdCard>
    </Box>
  );
};

export default Login;
